import { FaFacebookF, FaBars } from 'react-icons/fa';
import { AiOutlineTwitter, AiOutlineInstagram, AiFillLinkedin, AiFillYoutube, AiOutlineWhatsApp, AiOutlineSearch } from 'react-icons/ai';

export default function TeamSmallSlider(props) {
    return (
        <>
            <div className="relative after:absolute py-12 bg-[#F9F9F9] cursor-pointer after:w-full after:h-full after:top-0 after:left-0 after:bg-mainRed after:z-0 after:opacity-0 after:transition-all after:duration-300 hover:after:opacity-100 group">
                <div className="flex justify-center items-center relative z-20">
                    <img src={props.image} className="w-[200px] h-[200px] rounded-full" />
                    <div className="w-[200px] h-[200px] rounded-full absolute  bg-[#9e4b4780] flex justify-center items-center opacity-0 group-hover:opacity-100">
                        <ul className='flex space-x-4'>
                            <li><a href="" className='text-white text-[16px] transition-all duration-300 cursor-pointer hover:text-mainRed'><FaFacebookF /></a></li>
                            <li><a href="" className='text-white text-[16px] transition-all duration-300 cursor-pointer hover:text-mainRed'><AiOutlineTwitter /></a></li>
                            <li><a href="" className='text-white text-[16px] transition-all duration-300 cursor-pointer hover:text-mainRed'><AiOutlineInstagram /></a></li>
                            <li><a href="" className='text-white text-[16px] transition-all duration-300 cursor-pointer hover:text-mainRed'><AiFillLinkedin /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="text-center relative  group-hover:z-20">
                    <p className="mt-8 font-prata font-bold text-mainRed text-[14px] transition-all duration-300 group-hover:text-white">{props.proffesion}</p>
                    <p className="text-[24px] font-prata font-medium mt-4 transition-all duration-300 group-hover:text-white">{props.name}</p>
                </div>
            </div>
        </>
    )
}