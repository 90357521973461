import ReactCompareImage from 'react-compare-image';
import FirstImg from "../../assets/img/banner-2.jpg";
import SecondImg from "../../assets/img/banner-1-1.jpg";
export default function HomeAfterBefore() {
    return (
        <>
            <div className='bg-gradient-to-t from-white to-[#F9F9F9]'>
                <div className='max-w-screen-2xl mx-auto'>
                    <ReactCompareImage leftImage={FirstImg} rightImage={SecondImg} />
                </div>
            </div>


        </>
    )
}