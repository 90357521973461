export default function ProductCard (props){
    return (
        <>
            <div className="sm:w-[40%] max-lg:mt-4 lg:w-1/4 py-8 cursor-pointer bg-white rounded-2xl flex flex-col justify-between items-center">
                <div className="lg:w-56 lg:h-56 xl:w-64 xl:h-64 flex justify-center items-center">
                    <img src={props.image} />
                </div>
                <div>
                    <p className="font-bold font-prata text-mainRed  ">{props.price}</p>
                    <p className="font-prata mt-2">{props.name}</p>
                </div>
            </div>
        </>
    )
}

