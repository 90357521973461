import { Route, Routes } from "react-router-dom";
import Footer from "./Pages/Layouts/footer";
import Header from "./Pages/Layouts/header";
import Home from "./Pages/home";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
function App() {
  return (
    <>
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      <Footer />
    </>
  );
}

export default App;
