import Slider from "react-slick";
import HomeFirstSlider from "../PropsComponents/HomeFirstSlider";
export default function HomeFirst() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false,
    };
    return (
        <>
            <div className="w-full bg-home-first-bg flex justify-start sm:items-center h-auto sm:h-[100vh]" id="Home">

                <div className="w-full py-4">
                    <div className="max-xl:px-12 max-w-screen-2xl mx-auto">
                        <div className="xl:w-1/2 lg:pr-36 max-sm:pb-8">
                            <Slider {...settings}>
                                    <HomeFirstSlider desc={'Perfect Face Perfection'} title={'Perfect Face Surgery For Everyone.'} desc2={'Progery reiterates the pledge to help all and give hope to those who have lost hope.'} />
                                    <HomeFirstSlider desc={'Perfect Face Perfection'} title={'Perfect Face Surgery For Everyone.'} desc2={'Progery reiterates the pledge to help all and give hope to those who have lost hope.'} />
                                    <HomeFirstSlider desc={'Perfect Face Perfection'} title={'Perfect Face Surgery For Everyone.'} desc2={'Progery reiterates the pledge to help all and give hope to those who have lost hope.'} />
                                    <HomeFirstSlider desc={'Perfect Face Perfection'} title={'Perfect Face Surgery For Everyone.'} desc2={'Progery reiterates the pledge to help all and give hope to those who have lost hope.'} />
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}