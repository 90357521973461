import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import TabsSmall from '../PropsComponents/TabsSmall';
import BigImg1 from "../../assets/img/about-bg.png";
import BigImg2 from "../../assets/img/02.jpg";
import BigImg3 from "../../assets/img/03.jpg";
import BigImg4 from "../../assets/img/04.jpg";

export default function HomeTabs() {
    return (
        <>
            <div className='w-full bg-[#F9F9F9] py-[120px]' id='Services'>
                <div className='max-w-screen-2xl mx-auto'>
                    <Tabs>
                        <TabList>
                            <Tab>Chemical Research</Tab>
                            <Tab>Advanced Surgery</Tab>
                            <Tab>Skins Renewal</Tab>
                            <Tab>Saline Implants</Tab>
                        </TabList>

                        <TabPanel>
                            <TabsSmall title={'Chemical Research'} Bigtitle={'Facts you need to know ahead consequences.'} BigImage={BigImg1} />
                        </TabPanel>
                        <TabPanel>
                            <TabsSmall title={'Advanced Surgery'} Bigtitle={'Residents and fellows to practice surgical'} BigImage={BigImg2} />
                        </TabPanel>
                        <TabPanel>
                            <TabsSmall title={'Skins Renewal'} Bigtitle={'The hands-on, in-vivo lab includes'} BigImage={BigImg3} />
                        </TabPanel>
                        <TabPanel>
                            <TabsSmall title={'Saline Implants'} Bigtitle={'Residents and fellows to practice'} BigImage={BigImg4} />
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </>
    )
}