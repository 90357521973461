import { BsTelephoneFill, BsEnvelope } from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";
import { AiFillFacebook, AiOutlineTwitter, AiFillLinkedin } from 'react-icons/ai';
import { Link } from "react-scroll";
export default function HomeContact() {
    return (
        <>
            <div className="w-full bg-[#F7F7F7] py-24" id="Contact">
                <div className="max-w-screen-2xl mx-auto flex max-md:flex-col ShaD">
                    <div className="md:w-2/5 bg-home-contact bg-cover py-[30px] sm:py-[80px] px-[20px] sm:px-[60px]">
                        <div className="">
                            <div>
                                <p className="text-white text-[20px] font-prata font-medium">Catch Us Here</p>
                                <p className="text-white text-[16px]">Get a solid plan anytime & from anywhere</p>
                            </div>
                            <div className="flex space-x-4 py-8 border-b-[1px] border-[#a55d59]">
                                <div className="text-white">
                                    <BsTelephoneFill className="-rotate-90" />
                                </div>
                                <div className="">
                                    <p className="m-0 leading-none text-[14px] text-[#cba7a5]">Phone Number</p>
                                    <p className="mt-2 text-[14px] sm:text-[20px] text-white">098. 098.756. 76</p>
                                </div>
                            </div>
                            <div className="flex space-x-4 py-8 border-b-[1px] border-[#a55d59]">
                                <div className="text-white">
                                    <BsEnvelope className="" />
                                </div>
                                <div className="">
                                    <p className="m-0 leading-none text-[14px] text-[#cba7a5]">Email Address</p>
                                    <p className="mt-2 text-[14px] sm:text-[20px] text-white">info@newwebmail.com</p>
                                </div>
                            </div>
                            <div className="flex space-x-4 py-8">
                                <div className="text-white">
                                    <MdLocationPin className="" />
                                </div>
                                <div className="">
                                    <p className="m-0 leading-none text-[14px] text-[#cba7a5]">Office Address</p>
                                    <p className="mt-2 text-[14px] sm:text-[20px] text-white">12/a, New Hall Tower New York, US</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <ul className="flex space-x-4">
                                <li className="w-[46px] h-[46px] flex justify-center items-center text-white border-[2px] border-[#be7370] group transition-all duration-300 hover:bg-[#fff] hover:text-mainRed rounded-xl cursor-pointer">
                                    <a href="">
                                        <AiFillFacebook />
                                    </a>
                                </li>
                                <li className="w-[46px] h-[46px] flex justify-center items-center text-white border-[2px] border-[#be7370] group transition-all duration-300 hover:bg-[#fff] hover:text-mainRed rounded-xl cursor-pointer">
                                    <a href="">
                                        <AiOutlineTwitter />
                                    </a>
                                </li>
                                <li className="w-[46px] h-[46px] flex justify-center items-center text-white border-[2px] border-[#be7370] group transition-all duration-300 hover:bg-[#fff] hover:text-mainRed rounded-xl cursor-pointer">
                                    <a href="">
                                        <AiFillLinkedin />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="md:w-3/5 border p-[20px] sm:p-[60px] bg-white">
                        <div>
                            <div>
                                <p className="text-[24px] font-prata">Make Your Request</p>
                                <p className="text-[14px] sm:text-[16px] text-[#777]">Get a solid plan anytime & from anywhere</p>
                            </div>
                        </div>
                        <div className="mt-12">
                            <form className="w-full">
                                <div className="flex md:space-x-4 max-md:space-y-4 max-md:flex-col">
                                    <div className="md:w-1/2">
                                        <input type="text" className="w-full text-[14px] text-[#999999] rounded-[10px] h-[70px] pl-[30px] bg-[#f5f5f5] focus:outline-0" placeholder="Full Name" />
                                    </div>
                                    <div className="md:w-1/2">
                                        <input type="email" className="w-full text-[14px] text-[#999999] rounded-[10px] h-[70px] pl-[30px] bg-[#f5f5f5] focus:outline-0" placeholder="Enter Email" />
                                    </div>
                                </div>
                                <div className="flex mt-6 space-x-4">
                                    <div className="w-full">
                                        <select type="text" className="w-full text-[14px] text-[#999999] rounded-[10px] h-[70px] pl-[30px] bg-[#f5f5f5] focus:outline-0" placeholder="Full Name">
                                            <option></option>
                                            <option className="Opts py-12">Select Subject</option>
                                            <option className="Opts py-12">Bangla</option>
                                            <option className="Opts py-12">English</option>
                                            <option className="Opts py-12">Math</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="flex mt-6 space-x-4">
                                    <div className="w-full">
                                        <textarea type="text" rows={10} className="w-full text-[14px] max-sm:h-[120px] text-[#999999] rounded-[10px] pt-[30px] pl-[30px] bg-[#f5f5f5] focus:outline-0" placeholder="Enter Message">

                                        </textarea>
                                    </div>
                                </div>
                                <div className="flex mt-6 space-x-4">
                                    <button type="submit" className="py-[18px] px-[30px] bg-mainRed text-white font-bold rounded-[10px] transition-all duration-300 hover:bg-white hover:text-mainRed">
                                        Submit Request
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
