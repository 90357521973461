import { useState, useEffect } from 'react';
import { FaFacebookF, FaBars } from 'react-icons/fa';
import { AiOutlineTwitter, AiOutlineInstagram, AiFillLinkedin, AiFillYoutube, AiOutlineWhatsApp, AiOutlineSearch } from 'react-icons/ai';
import { HiBars3 } from 'react-icons/hi';
import Logo from '../../assets/img/logo.png';
import LogoWhite from '../../assets/img/logo-white.png';
import GetAppointment from '../PropsComponents/GetAppointment';
import { Link } from 'react-scroll';
import SmallMenuAddress from '../PropsComponents/SmallMenuAddress';
import Img1 from "../../assets/img/m1.jpg";
import Img2 from "../../assets/img/m2.jpg";
import Img3 from "../../assets/img/m3.jpg";
import Img4 from "../../assets/img/m4.jpg";
import Img5 from "../../assets/img/m5.jpg";
import Img6 from "../../assets/img/m6.jpg";
import "../../assets/js/script";
export default function Header() {
    const [close, setClose] = useState(false);
const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    console.log(scrollTop)
  }, []);

    return (
        <>
            <div className="w-full  sm:fixed z-50 px-10 top-0 transition-all duration-1000" id='Navbar'>
                <div id='Small_Menu_Hidden' className="flex justify-between max-w-screen-2xl mx-auto py-4 max-md:hidden transition-all duration-1000">
                    <div className='text-[#2b3137] text-[14px]'>Welcome to our company</div>
                    <div>
                        <ul className='flex space-x-4 items-center'>
                            <li><a href="" className='text-[#9f9387] text-[14px] transition-all duration-300 cursor-pointer hover:text-mainRed'><FaFacebookF /></a></li>
                            <li><a href="" className='text-[#9f9387] text-[14px] transition-all duration-300 cursor-pointer hover:text-mainRed'><AiOutlineTwitter /></a></li>
                            <li><a href="" className='text-[#9f9387] text-[14px] transition-all duration-300 cursor-pointer hover:text-mainRed'><AiOutlineInstagram /></a></li>
                            <li><a href="" className='text-[#9f9387] text-[14px] transition-all duration-300 cursor-pointer hover:text-mainRed'><AiFillLinkedin /></a></li>
                            <li><a href="" className='text-[#9f9387] text-[14px] transition-all duration-300 cursor-pointer hover:text-mainRed'><AiFillYoutube /></a></li>
                            <li><a href="" className='text-[#9f9387] text-[14px] transition-all duration-300 cursor-pointer hover:text-mainRed'><AiOutlineWhatsApp /></a></li>
                        </ul>
                    </div>
                </div>
                <div id='Main_Menu' className='shadow-20xl max-w-screen-2xl mx-auto flex max-md:justify-between transition-all duration-1000 items-center bg-[#FFFFFF] py-6 px-8 rounded-[6px]'>
                    <div className='pr-8'><img src={Logo} className='sm:w-[140px] sm:h-[39px]' /></div>
                    <div className='w-[360px] max-lg:hidden'><GetAppointment /></div>
                    <div className='flex justify-between md:w-full md:flex-shrink'>
                        <ul className='flex items-center space-x-8 xl:space-x-12 max-md:hidden'>
                            <li>
                                <Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to={'Home'} className='open_header_link'>Home</Link>
                            </li>
                            <li>
                                <Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to={'About'} className='open_header_link'>About</Link>
                            </li>
                            <li>
                                <Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to={'Services'} className='open_header_link'>Services</Link>
                            </li>
                            <li>
                                <Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to={'Team'} className='open_header_link'>Our Team</Link>
                            </li>
                            <li>
                                <Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to={'News'} className='open_header_link'>News</Link>
                            </li>
                            <li>
                                <Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to={'Contact'} className='open_header_link'>contact</Link>
                            </li>
                        </ul>
                        <div className='flex space-x-2'>
                            <div className='text-[14px] text-mainRed w-[30px]  sm:w-[60px] h-[30px]  sm:h-[60px] flex justify-center items-center rounded-[10px] bg-[#fff7ef] transition-all duration-300 hover:bg-mainRed hover:text-white cursor-pointer'>
                                <AiOutlineSearch />
                            </div>
                            <div onClick={() => setClose(!close)} className='text-[14px] text-mainRed w-[30px]  sm:w-[60px] h-[30px]  sm:h-[60px] flex justify-center items-center rounded-[10px] bg-[#fff7ef] transition-all duration-300 hover:bg-mainRed hover:text-white cursor-pointer'>
                                <FaBars />
                            </div>
                        </div>
                    </div>

                </div>
                <div className={close ? 'Open' : 'Close'}>
                    <div className='flex justify-end px-6 py-2'>
                        <button className='text-mainRed border border-mainRed w-8 h-8 text-[12px]' onClick={() => setClose(!close)}>X</button>
                    </div>
                    
                    <div className='px-8'>
                        <div className='cursor-pointer pb-8 border-b-[1px] border-[#344159]'>
                            <Link><img src={LogoWhite} className='w-[140px] h-[39px]' /></Link>
                        </div>
                        <div>
                        <ul className='flex flex-col w-full md:hidden'>
                            <li className='transition-all duration-300 py-2 cursor-pointer hover:bg-[rgba(255,255,255,0.1)] border-b-[1px] border-[#344159]'>
                                <Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to={'Home'} className='close_header_link'>Home</Link>
                            </li>
                            <li className='transition-all duration-300 py-2 cursor-pointer hover:bg-[rgba(255,255,255,0.1)] border-b-[1px] border-[#344159]'>
                                <Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to={'About'} className='close_header_link'>About</Link>
                            </li>
                            <li className='transition-all duration-300 py-2 cursor-pointer hover:bg-[rgba(255,255,255,0.1)] border-b-[1px] border-[#344159]'>
                                <Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to={'Services'} className='close_header_link'>Services</Link>
                            </li>
                            <li className='transition-all duration-300 py-2 cursor-pointer hover:bg-[rgba(255,255,255,0.1)] border-b-[1px] border-[#344159]'>
                                <Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to={'Team'} className='close_header_link'>Our Team</Link>
                            </li>
                            <li className='transition-all duration-300 py-2 cursor-pointer hover:bg-[rgba(255,255,255,0.1)] border-b-[1px] border-[#344159]'>
                                <Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to={'News'} className='close_header_link'>News</Link>
                            </li>
                            <li className='transition-all duration-300 py-2 cursor-pointer hover:bg-[rgba(255,255,255,0.1)] border-b-[1px] border-[#344159]'>
                                <Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to={'Contact'} className='close_header_link'>contact</Link>
                            </li>
                        </ul>
                        </div>
                        <div>
                            <SmallMenuAddress title={'Office Address'} desc1={'Ave 14th Street, Mirpur 210, San Franciso,'} desc2={'USA 3296.'} />
                            <SmallMenuAddress title={'Phone Number'} desc1={'+0989 7876 9865 9'} desc2={'+3333 4343 2233'} />
                            <SmallMenuAddress title={'Email Address'} desc1={'info@example.com'} desc2={'admin@example.com'} />
                        </div>
                        <div>
                        <ul className='flex space-x-4 py-8 items-center md:hidden'>
                            <li><a href="" className='text-white text-[19px] transition-all duration-300 cursor-pointer hover:text-mainRed'><FaFacebookF /></a></li>
                            <li><a href="" className='text-white text-[19px] transition-all duration-300 cursor-pointer hover:text-mainRed'><AiOutlineTwitter /></a></li>
                            <li><a href="" className='text-white text-[19px] transition-all duration-300 cursor-pointer hover:text-mainRed'><AiOutlineInstagram /></a></li>
                            <li><a href="" className='text-white text-[19px] transition-all duration-300 cursor-pointer hover:text-mainRed'><AiFillLinkedin /></a></li>
                            <li><a href="" className='text-white text-[19px] transition-all duration-300 cursor-pointer hover:text-mainRed'><AiFillYoutube /></a></li>
                            <li><a href="" className='text-white text-[19px] transition-all duration-300 cursor-pointer hover:text-mainRed'><AiOutlineWhatsApp /></a></li>
                        </ul>
                    </div>
                        <div className='mt-3 max-md:hidden'>
                            <div className='flex flex-row space-x-2'>
                                <img src={Img1} className='cursor-pointer w-1/3  h-[94px]' />
                                <img src={Img2} className='cursor-pointer w-1/3  h-[94px]' />
                                <img src={Img3} className='cursor-pointer w-1/3  h-[94px]' />
                            </div>
                            <div className='flex flex-row space-x-2 mt-2'>
                                <img src={Img4} className='cursor-pointer w-1/3  h-[94px]' />
                                <img src={Img5} className='cursor-pointer w-1/3  h-[94px]' />
                                <img src={Img6} className='cursor-pointer w-1/3  h-[94px]' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}