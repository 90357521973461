import { Link } from "react-scroll";
import { AiFillFacebook, AiOutlineTwitter, AiFillLinkedin } from 'react-icons/ai';
import { FaCalendarAlt } from "react-icons/fa";
import N1 from "../../assets/img/n1.jpg";
import N2 from "../../assets/img/n2.jpg";
import N3 from "../../assets/img/n3.jpg";
export default function Footer() {
    return (
        <>
            <div className="w-full bg-[#000000] pt-24">
                <div className="flex max-md:flex-wrap md:space-x-6 max-w-screen-2xl mx-auto max-xl:px-6">
                    <div className="sm:w-1/2 max-md:mt-6 md:w-1/4">
                        <h1 className="text-white text-[24px] font-serif">About Us</h1>
                        <p className="text-[#999999] mt-6 pr-12">
                            Finding the mental wherewithal to get into workout mode can take some serious effort, especially when all your activities happen at home. It can be done, but you have
                        </p>
                        <h1 className="text-white text-[24px] font-serif mt-12">Social Connect</h1>
                        <ul className="flex space-x-4 mt-6">
                            <li className="w-[46px] h-[46px] flex justify-center items-center text-[#777777] border-[2px] border-[#181818] group transition-all duration-300 hover:bg-mainRed hover:text-white rounded-xl cursor-pointer">
                                <a href="">
                                    <AiFillFacebook />
                                </a>
                            </li>
                            <li className="w-[46px] h-[46px] flex justify-center items-center text-[#777777] border-[2px] border-[#181818] group transition-all duration-300 hover:bg-mainRed hover:text-white rounded-xl cursor-pointer">
                                <a href="">
                                    <AiOutlineTwitter />
                                </a>
                            </li>
                            <li className="w-[46px] h-[46px] flex justify-center items-center text-[#777777] border-[2px] border-[#181818] group transition-all duration-300 hover:bg-mainRed hover:text-white rounded-xl cursor-pointer">
                                <a href="">
                                    <AiFillLinkedin />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="sm:w-1/2 max-md:mt-6 md:w-1/4">
                        <h1 className="text-white text-[24px] font-serif">Our Sections</h1>
                        <ul className="text-[#999999] mt-6">
                            <li><Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to={'Home'} className="font-bold font-sans cursor-pointer transition-all duration-300 hover:text-mainRed">HOME</Link></li>
                            <li><Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to={'About'} className="font-bold font-sans cursor-pointer transition-all duration-300 hover:text-mainRed">ABOUT</Link></li>
                            <li><Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to={'Services'} className="font-bold font-sans cursor-pointer transition-all duration-300 hover:text-mainRed">SERVICES</Link></li>
                            <li><Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to={'Team'} className="font-bold font-sans cursor-pointer transition-all duration-300 hover:text-mainRed">Our Team</Link></li>
                            <li><Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to={'News'} className="font-bold font-sans cursor-pointer transition-all duration-300 hover:text-mainRed">NEWS</Link></li>
                            <li><Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to={'Contact'} className="font-bold font-sans cursor-pointer transition-all duration-300 hover:text-mainRed">CONTACT</Link></li>
                        </ul>
                    </div>
                    <div className="sm:w-1/2 max-md:mt-6 md:w-1/4">
                        <h1 className="text-white text-[24px] font-serif">Get In Touch</h1>
                        <ul className="text-[#999999] mt-6">
                            <li className="">New Address</li>
                            <li className="text-[14px]">14/A, New Home Town, Nyc</li>
                            <li className="mt-6">Head Quatar</li>
                            <li className="text-[14px]">71, Qlex Tower, Onthon, UK</li>
                        </ul>
                    </div>
                    <div className="sm:w-1/2 max-md:mt-6 md:w-1/4">
                        <h1 className="text-white text-[24px] font-serif">News Feeds</h1>
                        <div className="mt-6">
                            <div className="flex max-lg:flex-col">
                                <div className=" lg:w-2/5">
                                    <img src={N1} />
                                </div>
                                <div className="lg:w-3/5 max-lg:mt-2 lg:px-2 leading-none flex flex-col justify-evenly">
                                    <Link className="font-bold font-sans cursor-pointer text-white text-[16px] transition-all duration-300 hover:text-mainRed">Dharma Home Suites at Novia offers</Link>
                                    <p className="text-[#777777] text-[16px] flex items-center space-x-2"><FaCalendarAlt className="text-mainRed" /> <span>March 17, 2021</span></p>
                                </div>
                            </div>
                            <div className="flex mt-4 max-lg:flex-col">
                                <div className=" lg:w-2/5">
                                    <img src={N1} />
                                </div>
                                <div className="lg:w-3/5 max-lg:mt-2 lg:px-2 leading-none flex flex-col justify-evenly">
                                    <Link className="font-bold font-sans cursor-pointer text-white text-[16px] transition-all duration-300 hover:text-mainRed">Dharma Home Suites at Novia offers</Link>
                                    <p className="text-[#777777] text-[16px] flex items-center space-x-2"><FaCalendarAlt className="text-mainRed" /> <span>March 17, 2021</span></p>
                                </div>
                            </div>
                            <div className="flex mt-4 max-lg:flex-col">
                                <div className=" lg:w-2/5">
                                    <img src={N1} />
                                </div>
                                <div className="lg:w-3/5 max-lg:mt-2 lg:px-2 leading-none flex flex-col justify-evenly">
                                    <Link className="font-bold font-sans cursor-pointer text-white text-[16px] transition-all duration-300 hover:text-mainRed">Dharma Home Suites at Novia offers</Link>
                                    <p className="text-[#777777] text-[16px] flex items-center space-x-2"><FaCalendarAlt className="text-mainRed" /> <span>March 17, 2021</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-screen-2xl mx-auto bg-[#181818] text-center text-[#999999] py-4 mt-12">
                    Copyright ©2023 BDevs. All Rights Reserved
                </div>
            </div>
        </>
    )
}