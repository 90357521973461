import HomeContact from "./Components/HomeContact";
import HomeFirst from "./Components/HomeFirst";
import HomeParalax from "./Components/HomeParalax";
import HomePrice from "./Components/HomePrice";
import HomeTabs from "./Components/HomeTabs";
import HomeTeam from "./Components/HomeTeam";
import HomeAbout from "./Components/homeAbout";
import HomeAfterBefore from "./Components/homeAfterBefore";
import HomeBlog from "./Components/homeBlog";
import HomeProducts from "./Components/homeProducts";

export default function Home() {
    return(
        <>
            <HomeFirst />
            <HomeAbout />
            <HomeTabs />
            <HomeAfterBefore />
            <HomeTeam />
            <HomeProducts />
            <HomeParalax />
            {/* <HomePrice /> */}
            <HomeContact />
            <HomeBlog />
        </>
    )
}