import $ from 'jquery';



$(document).ready(function() {

    $(window).scroll(function() {
  
      var scroll = $(window).scrollTop();
  

        if(scroll > 20){
           $('#Small_Menu_Hidden').addClass('hidden');     
           $('#Navbar').addClass('fixed');     
           $('#Navbar').addClass('bg-[#FFFFFF]');     
           $('#Navbar').addClass('St_Header');     
           $('#Main_Menu').removeClass('shadow-20xl');     
        }else{
            $('#Small_Menu_Hidden').removeClass('hidden');     
            $('#Navbar').removeClass('fixed');     
            $('#Navbar').removeClass('bg-[#FFFFFF]'); 
           $('#Navbar').removeClass('St_Header');     

            $('#Main_Menu').addClass('shadow-20xl');   

        }
    });
  });