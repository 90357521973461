import SmallTabs_Flex from "./SmallTabs_Flex";
import TitleBigTitle from "./TitleBigTitle";
import SmallImg1 from "../../assets/img/f1.png";
import SmallImg2 from "../../assets/img/f2.png";
export default function TabsSmall(props) {
    return (
        <div className="w-full flex bg-white rounded-2xl  overflow-hidden mt-16 max-lg:flex-col">
            <div className="lg:w-1/2 p-[20px] sm:p-[80px]">
                <TitleBigTitle title={props.title} Bigtitle={props.Bigtitle} />
                <div className="mt-8">
                    <SmallTabs_Flex imageSmall={SmallImg1} SmallTitle={'Surgery Preparation'} SmallDesc={'of medicine focused on enhancing'} />
                    <SmallTabs_Flex imageSmall={SmallImg2} SmallTitle={'Surgery Preparation'} SmallDesc={'of medicine focused on enhancing'} />
                </div>
            </div>
            <div className="lg:w-1/2">
                <img src={props.BigImage} className="w-full h-full object-cover" />
            </div>
        </div>
    )
}