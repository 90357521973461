import AbImg1 from "../../assets/img/abdominoplasty (1).png";
import AbImg2 from "../../assets/img/anatomy.png";
import AbImg3 from "../../assets/img/asymmetry.png";
import AbImg4 from "../../assets/img/aesthetic.png";
import AbImg5 from "../../assets/img/beauty.png";
import a1 from "../../assets/img/a1.png";
import a2 from "../../assets/img/a2.png";
import AboutSmall from "../PropsComponents/AboutSmall";
import TitleBigTitle from "../PropsComponents/TitleBigTitle";
import { AiOutlineCheck } from 'react-icons/ai';
export default function HomeAbout() {
    const AboutSMallData = [
        { img: AbImg1, title: "Liposaction", desc: "NewLook unlike many traditional plastic surgery centers in San Diego" },
        { img: AbImg2, title: "Non Surgical", desc: "Chip shop bonnet barney owt to do with me what a plonker hotpot loo" },
        { img: AbImg3, title: "Breast Implants", desc: "Spend a penny a load of old tosh get stuffed mate I don’t want no agro" },
        { img: AbImg3, title: "Lips Surgery", desc: "Cheeky is the bugger goal starkers lemon squeezy lost the plot done" },
        { img: AbImg3, title: "Pender Unity", desc: "Nancy boy Charl down the pub get stuffed mate easy peasy brown bread" },
    ]

    return (
        <>
            <div className="w-full py-10 mt-[120px] pb-[60px] sm:pb-[120px]" id="About">
                <div className="max-w-screen-2xl mx-auto flex max-lg:flex-wrap max-lg:justify-center">
                    {
                        AboutSMallData && AboutSMallData.map((item, index) => {
                            return <AboutSmall img={item.img} title={item.title} desc={item.desc} />
                        })
                    }
                </div>
                <div className="max-w-screen-2xl mx-auto max-xl:px-6 max-xl:flex-col max-xl:space-y-12 flex mt-[60px] ">
                    <div className="xl:w-1/2 pb-24 flex max-md:flex-col  relative after:bg-mainRed after:absolute after:w-[440px] after:h-[400px] after:bottom-0 after:right-0 after:z-0 after:rounded-[10px]">
                        <div className="max-md:mx-auto sm:w-1/2 relative flex justify-center z-10">
                            <img src={a1} className="" />
                        </div>
                        <div className="max-md:mx-auto sm:w-1/2 relative flex justify-center z-10 top-12">
                            <img src={a2} className="" />
                        </div>
                    </div>
                    <div className=" xl:w-1/2 md:px-12">
                        <div>
                            <TitleBigTitle title="About Us" Bigtitle="We are the leading plastic surgery center." />
                            <div className="my-6 py-6 border-t-[1px] border-b-[1px] border-[#e2e2e2] text-[16px] sm:text-[20px] text-[#777777]">
                                Progery reiterates the pledge to help all and give hope to those who have lost hope.
                            </div>
                            <div className="my-6 py-6  text-[16px] sm:text-[20px] text-[#777777]">
                                NewLook unlike many traditional plastic surgery centers in San Diego, are industry recognized experts in a wide variety of cosmetic procedure and are dedicated to providing customers.
                            </div>
                            <div className="flex max-md:flex-col max-md:space-y-6">
                                <div className=" md:w-1/2 flex justify-start space-x-6 items-center">
                                    <div className="w-[40px] h-[40px] rounded-full flex justify-center items-center bg-[#fff2f1] text-mainRed">
                                        <AiOutlineCheck />
                                    </div>
                                    <div className="text-[18px] font-roboto">
                                        Breast implant safety
                                    </div>
                                </div>
                                <div className=" md:w-1/2 flex justify-start space-x-6 items-center">
                                    <div className="w-[40px] h-[40px] rounded-full flex justify-center items-center bg-[#fff2f1] text-mainRed">
                                        <AiOutlineCheck />
                                    </div>
                                    <div className="text-[18px] font-roboto">
                                        Surgery preparation
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}