export default function SmallMenuAddress(props) {
    return(
        <>
            <div className="mt-8">
                <h3 className="font-roboto text-white text-[17px]">{props.title}</h3>
                <p className="text-[13px] font-roboto text-white opacity-50">{props.desc1}</p>
                <p className="text-[13px] font-roboto text-white opacity-50">{props.desc2}</p>
            </div>
        </>
    )
}