export default function SmallTabs_Flex(props) {
    return(
        <>
               <div className="flex mt-10 space-x-6">
                        <div className="h-16">
                            <img src={props.imageSmall} />
                        </div>
                        <div className="flex flex-col justify-between">
                            <p>{props.SmallTitle}</p>
                            <p>{props.SmallDesc}</p>
                        </div>
                    </div>
        </>
    )
}