import TitleBigTitle from "../PropsComponents/TitleBigTitle";
import Slider from "react-slick";
import T1 from "../../assets/img/t2.jpg";
import TeamSmallSlider from "../PropsComponents/TeamSmallSlider";
export default function HomeTeam() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows:false,
        dots:false,
        responsive: [
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
        ]
      };
    const TeamData = [
        {image:T1,proffesion:'Surgent',name:"Alex D.Denzel"},
        {image:T1,proffesion:'Surgent',name:"Alex D.Denzel"},
        {image:T1,proffesion:'Surgent',name:"Alex D.Denzel"}
    ]  ;
    return (
        <div className="max-w-screen-2xl mx-auto mt-24 max-xl:px-6 flex max-lg:flex-col" id="Team">
            <div className="lg:w-1/2 pr-24">
                <TitleBigTitle title={'Our Team'} Bigtitle={'Our experts team is ready for this kind solutions.'} />
                <div className="mt-8 border-t-[1px] py-12 text-[18px] text-[#777] border-[#ddd]">
                NewLook unlike many traditional plastic surgery centers in San Diego, are industry recognized experts in a wide variety of cosmetic procedure and are dedicated to providing customers.
                </div>
            </div>
            <div className="lg:w-1/2">
                <Slider {...settings}>
                    {
                        TeamData && TeamData.map((item,index) => {
                          return  <TeamSmallSlider image={item.image} proffesion={item.proffesion} name={item.name} />
                        })
                    }
                </Slider>
            </div>
        </div>
    )
}