import {AiOutlineFolderOpen} from "react-icons/ai";
import {BsCalendarCheck} from "react-icons/bs";
import { Link } from "react-router-dom";
export default function BlogCard(props){
    return(
        <>
            <div className="md:w-1/2 sm:px-4 lg:px-0 max-lg:mt-4 lg:w-1/4">
                <div>
                    <img src={props.image} />
                </div>
                <div className="flex text-mainRed font-serif text-[16px] space-x-4 mt-4">
                    <div className="flex space-x-2">
                        <div><AiOutlineFolderOpen /></div>
                        <div>Business</div>
                    </div>
                    <div className="flex space-x-2">
                        <div><BsCalendarCheck /></div>
                        <div>{props.date}</div>
                    </div>
                </div>
                <div className="mt-4">
                    <Link className="text-[18px] xl:text-[24px] font-serif transition-all duration-300 hover:text-mainRed">{props.title}</Link>
                </div>
            </div>
        </>
    )
}