import TitleBigTitle from '../PropsComponents/TitleBigTitle';
import B1 from "../../assets/img/b2.jpg";
import B2 from "../../assets/img/b3.jpg";
import B3 from "../../assets/img/b5.jpg";
import B4 from "../../assets/img/b4.jpg";
import BlogCard from '../PropsComponents/BlogCard';
export default function HomeBlog() {
    const BlogData = [
        {image:B1 , date:"Jun 19, 2021" , title:"Face Lift is a Non-surgical Surgery to"},
        {image:B2 , date:"Jun 22, 2021" , title:"Most Popular Breast Implant Surgeon"},
        {image:B3 , date:"Jun 24, 2021" , title:"Tips How to repair your car by yourselft in your gara"},
        {image:B4 , date:"Jun 30, 2021" , title:"Most Popular Breast Implant Surgeon in"},
    ]
    return(
        <>
            <div className="bg-white w-full" id='News'>
                <div className="max-w-screen-2xl mx-auto max-xl:px-6 py-12">
                    <TitleBigTitle title={'Blog'} Bigtitle={'Blogs & News'} />
                    <div className='w-full flex mt-12  lg:space-x-8 max-lg:flex-wrap'>
                         {
                            BlogData && BlogData.map((item,index) => {
                                return <BlogCard image={item.image} date={item.date} title={item.title} />
                            })
                         }   
                    </div>
                </div>
            </div>
        </>
    )
}