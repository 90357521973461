export default function AboutSmall(props){
    return(
        <>
            <div className="w-[90%] mx-auto sm:w-1/2 lg:w-1/5   lg:border-r-[1px] border-[#f1f1f1] py-8">
                <div className="flex justify-center py-6">
                    <img src={props.img} />
                </div>
                <div className="cursor-pointer font-normal text-[18px] xl:text-[20px] text-center font-prata text-black transition-all duration-300 hover:text-mainRed">
                    {props.title}
                </div>
                <div className="text-[#777777] text-center px-4 xl:px-12 font-roboto text-[14px] xl:text-[16px]">
                    {props.desc}
                </div>
            </div>
        </>
    )
}   