import AllService from "./AllService";

export default function HomeFirstSlider(props) {
    return (
        <>
            <div>
                <div>
                    <h3 className="text text-mainRed text-[18px] font-medium font-roboto">{props.desc}</h3>
                    <p className="text-black pb-[12px] sm:pb-[30px] mt-2 sm:mt-6  border-b-[1px] border-[#e2e2e2] sm:text-[40px] md:text-[60px] font-normal font-prata">{props.title}</p>
                    <p className="sm:text-[18px] mt-2 sm:mt-6 text-[#777777] font-roboto">{props.desc2}</p>
                </div>
                <div className="my-12">
                    <AllService />
                </div>
            </div>
        </>
    )
}


