import TitleBigTitle from "../PropsComponents/TitleBigTitle";
import Pr1 from "../../assets/img/2-min.png";
import Pr2 from "../../assets/img/9-2.png";
import Pr3 from "../../assets/img/7.png";
import Pr4 from "../../assets/img/6.png";
import ProductCard from "../PropsComponents/ProductCard";
export default function HomeProducts(){
const Products = [
    {image:Pr1 , price:"$ 99.00" , name:"Wash Gel"},
    {image:Pr2 , price:"$ 99.00" , name:"Skin Care Serum"},
    {image:Pr3 , price:"$ 99.00" , name:"Cream Oil"},
    {image:Pr4 , price:"$ 99.00" , name:"Repair serum"},
]
    return(
        <>
            <div className="w-full bg-[#F9F9F9] py-12 mt-24">
                <div className="max-w-screen-xl mx-auto max-xl:px-6 text-center">
                    <TitleBigTitle title={'Botox Products'} Bigtitle = {'Featured Products.'} />
                    <div className="w-full flex max-lg:flex-wrap justify-evenly lg:space-x-6">
                        {
                            Products && Products.map((item,index) => {
                               return <ProductCard image={item.image} price={item.price} name={item.name} />
                            })
                        }
                    </div>
                </div>
            </div>        
        </>
    )
}

